<template>
  <div class="fs-page">
    <header v-if="headerVisible" class="fs-header">
      <div class="header-inr">
        <router-link :to="{ path: '/' }" title="Itel" class="logo fs-pic"><img loading="lazy"
            src="@/assets/images/logo.png" alt="Itel" /></router-link>
        <nav class="fs-navigation">
          <div class="header-quick fs-sp">
            <div class="item-top top-cart-view">
              <div class="cart-view" @click="gotoCart()">
                <span v-if="totalCount > 0" class="total-product"> {{ totalCount }}</span>
              </div>
            </div>
            <div v-if="lstore().token == undefined" class="item-top hdr-login" @click="toggleMenu">
              <router-link class="show" :title="$t('HomePage.Registration')" :to="$t('Route.Login')">{{
                $t("HomePage.Login")
              }}
              </router-link>
              <!--<router-link :title="$t('HomePage.Registration')" :to="$t('Route.Registration')">{{$t('HomePage.Registration')}}</router-link>-->
            </div>
            <div v-else class="item-top hdr-login">
              <a class="show">{{ lstore().data }}</a>
              <!-- <a @click="logout()" :title="$t('HomePage.Logout')"><svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 17V18.3333C14.3333 19.3942 13.9119 20.4116 13.1618 21.1618C12.4116 21.9119 11.3942 22.3333 10.3333 22.3333H5C3.93913 22.3333 2.92172 21.9119 2.17157 21.1618C1.42143 20.4116 1 19.3942 1 18.3333V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H10.3333C11.3942 1 12.4116 1.42143 13.1618 2.17157C13.9119 2.92172 14.3333 3.93913 14.3333 5V6.33333M19.6667 17L25 11.6667L19.6667 17ZM25 11.6667L19.6667 6.33333L25 11.6667ZM25 11.6667H6.33333H25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a> -->
            </div>
            <!---->
            <!--
            <div class="item-top hdr-login bg-light">
              <router-link class="show text-primary" :to="{path:'/mua-hang'}">SIM SỐ ĐẸP</router-link>
            </div>-->
          </div>
          <ul class="js-navigation">
            <li data-nav="ve-itel">
              <router-link :to="{ path: $t('Route.Infomation') + '/' + AboutItel.Slug }" :title="$t('HomePage.about')">
                {{ $t("HomePage.about") }}
              </router-link>
            </li>
            <li data-nav="dich-vu" @click="toggleServiceMenu"
              :class="'has-child lv2 ' + (toggleService ? 'selected' : '')">
              <router-link class="fs-pc" :title="$t('HomePage.Service')" :to="{}">{{ $t("HomePage.Service") }}
              </router-link>
              <a class="fs-sp" :title="$t('HomePage.Service')">{{
                $t("HomePage.Service")
              }}</a>
              <div class="menu-items">
                <div class="menu-wrap">
                  <router-link title="Chọn số đẹp" :to="{ path: '/mua-hang' }" @click.native="reloadPage">Chọn số
                  </router-link>
                  <router-link title="Sim phong thuỷ" :to="{ path: '/sim-phong-thuy' }">Sim phong thuỷ</router-link>
                  <router-link title="Sim Data" :to="{ path: '/mua-sim-data' }">Sim Data</router-link>
                  <router-link title="$t('HomePage.DataPackage')" :to="{ path: $t('Route.PackLib') }">{{
                    $t("HomePage.DataPackage")
                  }}
                  </router-link>
                  <!-- <router-link
                    title="Mua Vietlot"
                    :to="{ path: $t('/vietlot') }"
                    >{{ $t("Mua Vietlot") }}</router-link
                  > -->
                </div>
              </div>
            </li>
            <li data-nav="cho-nha-dau-tu" class="">
              <router-link title="Điện thoại - Thiết bị" :to="{ path: '/' + $t('dien-thoai-thiet-bi') }">{{
                $t("Điện
                              thoại
                  - Thiết bị")
                              }}
              </router-link>
            </li>
            <li data-nav="diem-dich-vu" class="">
              <router-link title="Điểm dịch vụ khách hàng" :to="{ name: 'diem-dich-vu' }">{{
                $t("HomePage.CustomerServicePoint")
              }}
              </router-link>
            </li>
            <li data-nav="ho-tro" @click="toggleSupportMenu"
              :class="'has-child lv2 ' + (toggleSupport ? 'selected' : '')">
              <router-link class="fs-pc" :title="$t('HomePage.Support')" :to="{ path: $t('Route.Support') }">{{
                $t("HomePage.Support")
              }}
              </router-link>
              <a class="fs-sp" :title="$t('HomePage.Support')">{{
                $t("HomePage.Support")
              }}</a>
              <div class="menu-items">
                <div class="menu-wrap">
<!--                  <router-link :title="$t('HomePage.SimRegister')" :to="{-->
<!--                    path: $t('Route.Support') + $t('Route.SimRegister'),-->
<!--                  }">{{ $t("HomePage.SimRegister") }}-->
<!--                  </router-link>-->
                  <router-link :title="$t('HomePage.ESimExchange')" :to="{
                    path: $t('Route.Support') + $t('Route.ESimExchange'),
                  }">{{ $t("HomePage.ESimExchange") }}
                  </router-link>
                  <router-link :title="$t('HomePage.SimExchange')" :to="{
                    path: $t('Route.Support') + $t('Route.SimExchange'),
                  }">{{ $t("HomePage.SimExchange") }}
                  </router-link>
                  <!--<router-link :title="$t('HomePage.SimChangeUser')" :to="{path:$t('Route.Support')+$t('Route.SimChangeUser')}"
                    >{{$t('HomePage.SimChangeUser')}}</router-link>-->
                  <router-link :title="$t('HomePage.Faq')" :to="{ path: $t('Route.Support') + $t('Route.Faq') }">{{
                    $t("HomePage.Faq")
                  }}
                  </router-link>
                  <router-link :title="$t('HomePage.ContactUs')"
                    :to="{ path: $t('Route.Support') + $t('Route.ContactUs') }">{{
                      $t("HomePage.ContactUs")
                    }}
                  </router-link>
                  <router-link :title="$t('HomePage.Faq')" :to="{ path:$t('Route.UpdateTTTB')}">{{
                    $t("HomePage.UpdateTTTB")
                  }}
                  </router-link>
                </div>
              </div>
            </li>

            <li data-nav="club" class="club">
              <a href="/club">{{ $t("HomePage.itelClub") }}</a>
              <!-- <router-link title="ITEL CLUB"  to="/club">{{$t('HomePage.itelClub')}}</router-link> -->
            </li>
            <li class="has-child">
              <span>...</span>
              <div class="menu-items">
                <div class="menu-wrap">
                  <div class="wrap__child">
                    <router-link :to="{ name: 'tuyen-dung' }" data-nav="tuyen-dung" :title="$t('HomePage.Recruitment')">
                      {{ $t("HomePage.Recruitment") }}
                    </router-link>
                  </div>

                  <div class="wrap__child">
                    <router-link :to="{ path: $t('Route.Infomation') + '/' + ForInvestors.Slug }"
                      data-nav="cho-nha-dau-tu" :title="$t('HomePage.ForInvestors')">
                      {{ $t("HomePage.ForInvestors") }}
                    </router-link>
                  </div>
                  <div class="wrap__child">
                    <router-link :to="{ path: $t('Route.INews') }" data-nav="i-news" title="I-news">{{
                      $t("HomePage.INews")
                    }}
                    </router-link>
                  </div>
                  <div class="wrap__child">
                    <router-link data-nav="dai-ly" title="Đại lý" :to="{ path: $t('Route.Agency') }">{{
                      $t("HomePage.Agency")
                    }}
                    </router-link>
                  </div>
                  <template v-if="Campaigns.length > 0">
                    <div class="wrap__child" v-for="campaignItem in Campaigns" :key="campaignItem.Id">
                      <router-link v-if="campaignItem.IsExternal != 1" :data-nav="campaignItem.Slug"
                        :to="$t('Route.Campaign') + '/' + campaignItem.Slug" :title="campaignItem.Name">{{
                          $t(campaignItem.Name)
                        }}
                      </router-link>
                      <div @click="goToShow(campaignItem)" :title="$t(campaignItem.Name)"
                        v-else-if="campaignItem.Slug == 'ban-si-phu-kien-baseus'">
                        <a>{{
                          campaignItem.Name
                        }}
                        </a>
                      </div>
                      <a v-else :href="campaignItem.Slug" target="_blank" :title="$t(campaignItem.Name)">{{
                        campaignItem.Name
                      }}</a>
                    </div>
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div class="header-quick">
          <div class="item-top top-search-container">
            <i @click="quickSearchProduct()" v-if="isShowSearch" class="top-right-search "></i>
            <input ref="txtSearch" v-on:keyup.enter="quickSearchProduct()" v-model="sSearch" v-if="isShowSearch"
                   :class="isShowSearch ? 'top-search-focus' : ''" class="top-search" id="searchright" type="search"
                   name="q"
                   placeholder="Bạn tìm gì...">
            <i v-if="!isShowSearch" @click="showQuikcSearch(true);" class="top-mglass"></i>
            <ul class="ul-quick-search" v-if="isSearchResult" v-click-outside="hideResultSearch">
              <ul>
                <li v-if="keywordSearchResult.length>0">
                  <span class="title-search-header">Thông tin gợi ý</span>
                  <div v-for="(p, stt) in keywordSearchResult" :key="stt" :title="p.Name"
                       :style="stt < keywordSearchResult.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'KeyWord')">
                      <span class="title-search">{{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="keywordSearchResultShop.length>0">
                  <span class="title-search-header">Itel Shop</span>
                  <div v-for="(p, stt) in keywordSearchResultShop" :key="stt" :title="p.Name"
                       :style="stt < keywordSearchResultShop.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'ItelShop')">
                      <span class="title-search">{{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.AboutItel.length>0">
                  <span class="title-search-header">Thông tin về Itel</span>
                  <div v-for="(p, stt) in resultSearch.AboutItel" :key="stt" :title="p.Name"
                       :style="stt < resultSearch.AboutItel.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'AboutItel')">
                      <span class="title-search">{{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.CampaignMenus.length>0">
                  <span class="title-search-header">Chiến dịch của Itel</span>
                  <div v-for="(p, stt) in resultSearch.CampaignMenus" :key="stt" :title="p.Name"
                       :style="stt<resultSearch.CampaignMenus.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'CampaignMenus')">
                      <span class="title-search"> {{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.Recruitment.length>0">
                  <span class="title-search-header">Tuyển dụng ITEL</span>
                  <div style="float: left" class="py-1 w-100" v-for="(p, stt) in resultSearch.Recruitment" :key="stt"
                       :title="p.Title"
                       :style="stt<resultSearch.Recruitment.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-img" @click="watchNow(p,'Recruitment')">
                      <img :src="p.Thumbnail"/>
                    </div>
                    <div class="search-info" @click="watchNow(p,'Recruitment')">
                      <span class="title-search"> {{ p.Title }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.Campaigns.length>0">
                  <span class="title-search-header"
                        v-if="resultSearch.CampaignMenus.length<=0">Chiến dịch của Itel</span>
                  <div style="float: left" class="py-1" v-for="(p, stt) in resultSearch.Campaigns" :key="stt"
                       :title="p.Title"
                       :style="stt<resultSearch.Campaigns.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-img" @click="watchNow(p,'Campaigns')">
                      <img :src="p.Thumbnail"/>
                    </div>
                    <div class="search-info" @click="watchNow(p,'Campaigns')">
                      <span class="title-search"> {{ p.Title }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.ForInvestors.length>0">
                  <span class="title-search-header">Dành cho nhà đầu tư</span>
                  <div v-for="(p, stt) in resultSearch.ForInvestors" :key="stt" :title="p.Name"
                       :style="stt<resultSearch.ForInvestors.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'ForInvestors')">
                      <span class="title-search"> {{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.News.length>0">
                  <span class="title-search-header">Tin tức về Itel</span>
                  <div style="float: left" class="py-1" v-for="(p, stt) in resultSearch.News" :key="stt"
                       :title="p.Title"
                       :style="stt<resultSearch.News.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-img" @click="watchNow(p,'News')">
                      <img :src="p.Thumbnail"/>
                    </div>
                    <div class="search-info" @click="watchNow(p,'News')">
                      <span class="title-search"> {{ p.Title }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.BlogLoyaltys.length>0 ">
                  <span class="title-search-header">Itel Club</span>
                  <div style="float: left" class="py-1" v-for="(p, stt) in resultSearch.BlogLoyaltys" :key="stt"
                       :title="p.Title"
                       :style="stt<resultSearch.BlogLoyaltys.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-img" @click="watchNow(p,'BlogLoyaltys')">
                      <img :src="p.Thumbnail"/>
                    </div>
                    <div class="search-info" @click="watchNow(p,'BlogLoyaltys')">
                      <span class="title-search"> {{ p.Title }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.Vouchers && resultSearch.Vouchers.length>0">
                  <span v-if="resultSearch.BlogLoyaltys.length<=0" class="title-search-header">Itel Club</span>
                  <div style="float: left" class="py-1" v-for="(p, stt) in resultSearch.Vouchers" :key="stt"
                       :title="p.title"
                       :style="stt<resultSearch.Vouchers.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-img" @click="watchNow(p,'Vouchers')">
                      <img :src="p.image"/>
                    </div>
                    <div class="search-info" @click="watchNow(p,'Vouchers')">
                      <span class="title-search"> {{ p.title }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.StaticPages.length>0">
                  <span class="title-search-header">Trang gợi ý</span>
                  <div v-for="(p, stt) in resultSearch.StaticPages" :key="stt" :title="p.Name"
                       :style="stt<resultSearch.StaticPages.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'StaticPages')">
                      <span class="title-search"> {{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.DataPackages.length>0">
                  <span class="title-search-header">Gói cước</span>
                  <div v-for="(p, stt) in resultSearch.DataPackages" :key="stt" :title="p.Name"
                       :style="stt<resultSearch.DataPackages.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'DataPackages')">
                      <span class="title-search"> {{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.Categories.length>0 || categories.length>0">
                  <span class="title-search-header">Danh mục gợi ý</span>
                  <div v-for="(p, stt) in resultSearch.Categories" :key="stt" :title="p.category_name"
                       :style="stt<resultSearch.Categories.length-1 &&categories?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,'Category')">
                      <span class="title-search"> {{ p.category_name }}</span>
                    </div>
                  </div>
                  <div v-for="(p, stt) in categories" :key="stt" :title="p.Name"
                       :style="stt<categories.length-1 ?'border-bottom: 1px #b8b8b8 solid':''">
                    <div class="search-info" @click="watchNow(p,p.category)">
                      <span class="title-search"> {{ p.Name }}</span>
                    </div>
                  </div>
                </li>
                <li v-if="resultSearch.Products.length>0">
                  <p class="title-search-header">Sản phẩm gợi ý</p>
                  <div style="float: left" class="py-1" v-for="(p, stt) in resultSearch.Products" :key="stt"
                       :title="p.product_name">
                    <div class="search-img">
                      <img :src="p.image_url" @click="BuyNow(p)"/>
                    </div>
                    <div @click="BuyNow(p)" class="search-info">
                      <span class="title-search" v-html="p.product_name"></span>
                      <span class="price">{{ p.price | number }}đ</span>
                      <span class="base_price" v-if="p.discount_info || p.base_price > p.price">{{
                          p.base_price | number
                        }}đ</span>
                    </div>
                  </div>
                </li>
              </ul>
            </ul>
          </div>


          <div class="item-top top-cart-view">
            <div class="cart-view" @click="gotoCart()" title="Giỏ hàng">
              <span v-if="totalCount > 0" class="total-product"> {{ totalCount }}</span>
            </div>

          </div>
          <div v-if="lstore().token == undefined" class="item-top hdr-login">
            <router-link class="show" :title="$t('HomePage.Login')" :to="$t('Route.Login')">{{ $t("HomePage.Login") }}
            </router-link>
            <!--<router-link title="$t('HomePage.Registration')" :to="$t('Route.Registration')">{{$t('HomePage.Registration')}}</router-link>-->
          </div>
          <div v-else class="item-top hdr-login">
            <a class="show">{{ lstore().data }}</a>
            <!-- <a @click="logout()" :title="$t('HomePage.Logout')"> -->
            <!-- <svg width="22" height="20" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3333 17V18.3333C14.3333 19.3942 13.9119 20.4116 13.1618 21.1618C12.4116 21.9119 11.3942 22.3333 10.3333 22.3333H5C3.93913 22.3333 2.92172 21.9119 2.17157 21.1618C1.42143 20.4116 1 19.3942 1 18.3333V5C1 3.93913 1.42143 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1H10.3333C11.3942 1 12.4116 1.42143 13.1618 2.17157C13.9119 2.92172 14.3333 3.93913 14.3333 5V6.33333M19.6667 17L25 11.6667L19.6667 17ZM25 11.6667L19.6667 6.33333L25 11.6667ZM25 11.6667H6.33333H25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> -->
            <!-- </a> -->
            <div v-if="toggleLogoutMenu" class="user-login">
              <ul>
                <li @click="logout()">{{ $t("HomePage.Logout") }}</li>
              </ul>
            </div>
          </div>
          <!---->
          <!--

          <div class="item-top hdr-login bg-light">
            <router-link class="show text-primary" :to="{path:'/mua-hang'}">SIM SỐ ĐẸP</router-link
            ><router-link class="text-primary" :to="{path:'/mua-hang'}">SIM DATA</router-link>
          </div>-->
          <div v-if="lstore().token" class="fs-sp">
            <div class="item-top hdr-login" style="padding-top: 3px">
              <button type="button" class="text-white fs-sp" tabindex="0" v-b-tooltip.hover :title="$t('HomePage.Logout')"
                @click="logout">
                <img style="height: 22px" src="@/assets/images/logout.svg" alt="" />
              </button>
            </div>
          </div>
          <div class="fs-sp">
            <div class="item-top hdr-login" style="padding-top: 3px">
              <button @click="shipment()" type="button" class="text-white fs-sp" tabindex="0" v-b-tooltip.hover
                title="Theo dõi đơn hàng">
                <svg height="22" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.25 16.5H28.5V11.4328C28.5 10.8375 28.2609 10.2656 27.8391 9.84375L23.1562 5.16094C22.7344 4.73906 22.1625 4.5 21.5672 4.5H19.5V2.25C19.5 1.00781 18.4922 0 17.25 0H5.25C4.00781 0 3 1.00781 3 2.25V4.5H0.375C0.16875 4.5 0 4.66875 0 4.875V5.625C0 5.83125 0.16875 6 0.375 6H13.125C13.3313 6 13.5 6.16875 13.5 6.375V7.125C13.5 7.33125 13.3313 7.5 13.125 7.5H1.875C1.66875 7.5 1.5 7.66875 1.5 7.875V8.625C1.5 8.83125 1.66875 9 1.875 9H11.625C11.8313 9 12 9.16875 12 9.375V10.125C12 10.3313 11.8313 10.5 11.625 10.5H0.375C0.16875 10.5 0 10.6687 0 10.875V11.625C0 11.8313 0.16875 12 0.375 12H10.125C10.3313 12 10.5 12.1687 10.5 12.375V13.125C10.5 13.3313 10.3313 13.5 10.125 13.5H3V19.5C3 21.9844 5.01562 24 7.5 24C9.98438 24 12 21.9844 12 19.5H18C18 21.9844 20.0156 24 22.5 24C24.9844 24 27 21.9844 27 19.5H29.25C29.6625 19.5 30 19.1625 30 18.75V17.25C30 16.8375 29.6625 16.5 29.25 16.5ZM7.5 21.75C6.25781 21.75 5.25 20.7422 5.25 19.5C5.25 18.2578 6.25781 17.25 7.5 17.25C8.74219 17.25 9.75 18.2578 9.75 19.5C9.75 20.7422 8.74219 21.75 7.5 21.75ZM22.5 21.75C21.2578 21.75 20.25 20.7422 20.25 19.5C20.25 18.2578 21.2578 17.25 22.5 17.25C23.7422 17.25 24.75 18.2578 24.75 19.5C24.75 20.7422 23.7422 21.75 22.5 21.75ZM26.25 12H19.5V6.75H21.5672L26.25 11.4328V12Z"
                    fill="white" />
                </svg>
              </button>
            </div>
          </div>
          <div v-if="lstore().token" class="item-top hdr-login fs-pc">
            <button type="button" class="fz-1rem text-white fs-pc" tabindex="0" v-b-tooltip.hover
              :title="$t('HomePage.Logout')" @click="logout">
              <img style="height: 24px" src="@/assets/images/logout.svg" alt="" />
            </button>
          </div>
          <div class="item-top hdr-login fs-pc">
            <button @click="shipment()" type="button" class="fz-1rem text-white fs-pc" tabindex="0" v-b-tooltip.hover
              title="Theo dõi đơn hàng">
              <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.25 16.5H28.5V11.4328C28.5 10.8375 28.2609 10.2656 27.8391 9.84375L23.1562 5.16094C22.7344 4.73906 22.1625 4.5 21.5672 4.5H19.5V2.25C19.5 1.00781 18.4922 0 17.25 0H5.25C4.00781 0 3 1.00781 3 2.25V4.5H0.375C0.16875 4.5 0 4.66875 0 4.875V5.625C0 5.83125 0.16875 6 0.375 6H13.125C13.3313 6 13.5 6.16875 13.5 6.375V7.125C13.5 7.33125 13.3313 7.5 13.125 7.5H1.875C1.66875 7.5 1.5 7.66875 1.5 7.875V8.625C1.5 8.83125 1.66875 9 1.875 9H11.625C11.8313 9 12 9.16875 12 9.375V10.125C12 10.3313 11.8313 10.5 11.625 10.5H0.375C0.16875 10.5 0 10.6687 0 10.875V11.625C0 11.8313 0.16875 12 0.375 12H10.125C10.3313 12 10.5 12.1687 10.5 12.375V13.125C10.5 13.3313 10.3313 13.5 10.125 13.5H3V19.5C3 21.9844 5.01562 24 7.5 24C9.98438 24 12 21.9844 12 19.5H18C18 21.9844 20.0156 24 22.5 24C24.9844 24 27 21.9844 27 19.5H29.25C29.6625 19.5 30 19.1625 30 18.75V17.25C30 16.8375 29.6625 16.5 29.25 16.5ZM7.5 21.75C6.25781 21.75 5.25 20.7422 5.25 19.5C5.25 18.2578 6.25781 17.25 7.5 17.25C8.74219 17.25 9.75 18.2578 9.75 19.5C9.75 20.7422 8.74219 21.75 7.5 21.75ZM22.5 21.75C21.2578 21.75 20.25 20.7422 20.25 19.5C20.25 18.2578 21.2578 17.25 22.5 17.25C23.7422 17.25 24.75 18.2578 24.75 19.5C24.75 20.7422 23.7422 21.75 22.5 21.75ZM26.25 12H19.5V6.75H21.5672L26.25 11.4328V12Z"
                  fill="white" />
              </svg>
            </button>
          </div>
        </div>
        <div class="nav-but fs-sp" @click="toggleMenu" />
      </div>
    </header>
    <router-view />
    <footer class="fs-footer" v-if="footerVisible">
      <div class="footer-inr">
        <div class="ft-top">
          <div class="ft-inr">
            <div class="count-box">
              <div class="count-items">
                <div class="count-item ft-user">
                  <div class="fs-pic count-pic">
                    <img loading="lazy" src="@/assets/images/ft-user.svg" :alt="$t('Common.Customers')" />
                  </div>
                  <div class="count-txt">
                    <div class="count-numner" v-if="ConfigData.length > 0">
                      <span class="count-mask">{{
                        ConfigData.find((i) => i.DataKey == "NumberOfCustomer")
                          .Value | number
                      }}</span>
                      <span @click="runAnimations('.sub')" :countUp="
                        ConfigData.find(
                          (i) => i.DataKey == 'NumberOfCustomer'
                        ).Value
                      " class="sub count-ani">{{
  ConfigData.find(
    (i) => i.DataKey == "NumberOfCustomer"
  ).Value | number
}}</span>
                    </div>
                    <p>{{ $t("Common.Customers") }}</p>
                  </div>
                </div>
                <div class="count-item ft-location">
                  <div class="fs-pic count-pic">
                    <img loading="lazy" src="@/assets/images/ft-location.png" :alt="$t('Footer.Provinces')" />
                  </div>
                  <div class="count-txt">
                    <div class="count-numner">
                      <span class="count-mask">63</span>
                      <span @click="runAnimations('.sub')" :countUp="63" class="count-ani">63</span>
                    </div>
                    <p>{{ $t("Footer.Provinces") }}</p>
                  </div>
                </div>
                <div class="count-item ft-server">
                  <div class="fs-pic count-pic">
                    <img loading="lazy" src="@/assets/images/ft-server.png" :alt="$t('Footer.Subscription')" />
                  </div>
                  <div class="count-txt">
                    <div class="count-numner" v-if="ConfigData.length > 0">
                      <span class="count-mask">{{
                        ConfigData.find(
                          (i) => i.DataKey == "NumberOfSubscription"
                        ).Value | number
                      }}</span>
                      <span @click="runAnimations('.sub')" :countUp="
                        ConfigData.find(
                          (i) => i.DataKey == 'NumberOfSubscription'
                        ).Value
                      " class="count-ani">{{
  ConfigData.find(
    (i) => i.DataKey == "NumberOfSubscription"
  ).Value | number
}}</span>
                    </div>
                    <p>{{ $t("Footer.Subscription") }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-box">
              <div class="fs-box">
                <div class="small-title">
                  {{ $t("Footer.MOBILE SERVICES") }}
                </div>
                <ul class="ft-nav">
                  <li>
                    <router-link :to="$t('Route.BuySim')" :title="$t('Footer.Buy digital SIM')">
                      {{ $t("Footer.Buy digital SIM") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$t('Route.PackLib')" :title="$t('Footer.Purchase data package')">
                      {{ $t("Footer.Purchase data package") }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="fs-box">
                <div class="small-title">{{ $t("Footer.POLICY") }}</div>
                <ul class="ft-nav">
                  <li v-for="page in StaticPages.filter((i) => i.Type == 1)" :key="page.Id">
                    <router-link :to="$t('Route.Infomation') + '/' + page.Slug" :title="page.Name">
                      {{ page.Name }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="fs-box">
                <div class="small-title">{{ $t("Footer.SUPPORT") }}</div>
                <ul class="ft-nav">
                  <li>
                    <router-link :to="$t('Route.Support') + $t('Route.SimRegister')"
                      :title="$t('Footer.Register your account')">{{ $t("Footer.Register your account") }}
                    </router-link>
                  </li>

                  <li>
                    <router-link :to="$t('Route.Support') + $t('Route.SimExchange')" :title="$t('Footer.Change SIM')">{{
                      $t("Footer.Change SIM")
                    }}
                    </router-link>
                  </li>

                  <!-- eSim -->
                  <li>
                    <router-link :to="$t('Route.Support') + $t('Route.ESimExchange')" :title="$t('Footer.Change eSIM')">
                      {{ $t("Footer.Change eSIM") }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$t('Route.Support') + $t('Route.ContactUs')" :title="$t('Footer.Contact')">{{
                      $t("Footer.Contact")
                    }}
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="$t('Route.Support') + $t('Route.Faq')" :title="$t('Footer.FAQs')">{{
                      $t("Footer.FAQs")
                    }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="fs-box">
                <div class="small-title">{{ $t("Footer.PROCEDURE") }}</div>
                <ul class="ft-nav">
                  <li v-for="page in StaticPages.filter((i) => i.Type == 2)" :key="page.Id">
                    <router-link :title="page.Name" :to="$t('Route.Infomation') + '/' + page.Slug">
                      {{ page.Name }}
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="fs-box">
                <div class="small-title">{{ $t("Footer.GO WITH ITEL") }}</div>
                <div class="fl-itel">
                  <a href="https://www.facebook.com/itel.fan" title="Facebook" target="_blank">
                    <div class="fs-pic fl-pic">
                      <img loading="lazy" src="@/assets/images/ft-facebook.png" alt="Facebook" />
                    </div>
                    <div class="fl-txt">
                      <p>Facebook</p>
                    </div>
                  </a>
                  <a href="https://zalo.me/3281327437324952111" title="Zalo" target="_blank">
                    <div class="fs-pic fl-pic">
                      <img loading="lazy" src="@/assets/images/ft-zalo.png" alt="Zalo" />
                    </div>
                    <div class="fl-txt">
                      <p>Zalo</p>
                    </div>
                  </a>
                  <a href="https://www.instagram.com/itel.vn/" title="Instagram" target="_blank">
                    <div class="fs-pic fl-pic">
                      <img loading="lazy" src="@/assets/images/ft-in.png" alt="Instagram" />
                    </div>
                    <div class="fl-txt">
                      <p>Instagram</p>
                    </div>
                  </a>
                  <a href="http://online.gov.vn/Home/WebDetails/79259">
                    <div class="fs-pic fl-pic">
                      <img loading="lazy" src="@/assets/images/logoSaleNoti.png"
                        :alt="$t('Footer.Ministry of Industry and Trade')" />
                    </div>
                  </a>
                </div>
              </div>
              <div class="fs-box">
                <div class="small-title">
                  {{ $t("Footer.DOWNLOAD ITEL APP NOW") }}
                </div>
                <div class="ft-app">
                  <a class="fs-pic qa-code" href="javascript:void(0)">
                    <img loading="lazy" src="@/assets/images/ft-qa-code.png" alt="QA code" />
                  </a>
                  <div class="fs-apps">
                    <a href="https://play.google.com/store/apps/details?id=itelecom.vn.myitel" class="fs-pic"
                      title="Google Play" target="_blank">
                      <img loading="lazy" src="@/assets/images/ft-google-play.png" alt="Google Play" />
                    </a>
                    <a href="https://apps.apple.com/us/app/my-itel/id1610306087" class="fs-pic" title="Apple Store"
                      target="_blank">
                      <img loading="lazy" src="@/assets/images/ft-apple-store.png" alt="Apple Store" />
                    </a>
                  </div>
                </div>
                <div class="fs-txt txt-address">
                  <p>{{ $t("Common.ItelWebsite") }}</p>
                  <p>{{ $t("Common.ItelCompany") }}</p>
                  <p>{{ $t("Common.ItelNumberCompany") }}</p>
                  <p>{{ $t("Common.ItelOwnerContent") }}</p>
                  <p>
                    {{ $t("Common.Address") }}: {{ $t("Common.ItelAddress") }}
                  </p>
                  <p>Hotline: 0877 087 087({{ $t("Common.FreeFee") }})</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ft-bot">
          <div class="ft-inr">
            <div class="fs-box">
              <div class="go-top" @click="scrollTop()">Up</div>
              <div class="copyright">
                <p>Copyright ITEL 2021. All rights reserved.</p>
              </div>
            </div>
            <div class="fs-box">
              <ul class="nav-rules">
                <li v-for="page in StaticPages.filter((i) => i.Type == 3)" :key="page.id">
                  <a :title="page.Name" :href="'thong-tin/' + page.Slug">
                    {{ page.Name }}
                  </a>
                </li>
                <li v-for="page in StaticPages.filter((i) => i.Type == 5)" :key="page.id">
                  <a :title="page.Name" :href="'thong-tin/' + page.Slug">
                    {{ page.Name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <b-modal body-class="p-2" hide-footer id="loginPhone" title="" centered  >
      <div class="body-content-whole">
        <div class="row mb-3">
          <div class="col-xl-12 col-lg-12">
            <p class="text-header text-center">
              Bán sỉ phụ kiện Baseus
            </p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-12 col-lg-12">
            <div class="form-group">
              <label class="font-bold">Nhập số điện thoại để mua với giá sỉ</label>
              <input type="number" class="form-control" placeholder="0xxxxxxxxx"
                onKeyDown="if(this.value.length==10 && event.keyCode>47 && event.keyCode < 58) return false;"
                maxlength="10" :class="{ 'invalid': isValidMobileNumber, 'is-invalid': !isValidMobileNumber }"
                v-model="MobileNumber" @keyup="validateMobileNumber" />
              <div class="text-danger-error" v-if="!isValidMobileNumber">
                Số điện thoại không hợp lệ
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12  text-center">
            <div class="form-group">
              <b-button v-if="isLogin" @click="logoutPhone()" variant="primary"
                class="btn-primary btn-login-continues">Thoát
              </b-button>
              <b-button v-else @click="loginPhone()" variant="primary" class="btn-primary btn-login-continues">Tiếp tục
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal body-class="p-2" hide-footer hide-header id="confirmWholeSale" title="" no-close-on-backdrop="true"
      :close-on-esc="false" centered>
      <div class="body-content-whole">
        <div class="row mb-3">
          <div class="col-xl-12 col-lg-12">
            <p class="text-header text-center">
              Thông báo <br/>
              Điều kiện mua hàng với giá sỉ
            </p>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-xl-12 col-lg-12">
            <div class="form-group text-left">
              <label class="font-bold text-order">Đơn hàng cần thỏa mãn 2 điều kiện sau:</label>
              <br/>
              <label class="font-bold text-order">1. Giỏ hàng &#8805; 5 sản phẩm</label>
              <br/>
              <label class="font-bold text-order">2. Tổng đơn hàng &#8805; 5.250.000 VNĐ</label>
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12  text-center">
          <div class="form-group">
            <b-button @click="confirmClose()" variant="primary" class="btn-confirm-close">Quay lại
            </b-button>
            <b-button @click="confirmNext()" variant="primary" class="btn-primary btn-confirm-next">Tôi đã hiểu
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import "../../../assets/css/layout.css";
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import "../../../assets/css/vue-select.css";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { VBTooltip } from "bootstrap-vue";
import { OriginType } from '@/libs/acl/config';
import ClickOutside from 'vue-click-outside'
import router from "@/router";

const totalFrames = Math.round(2000 / (1000 / 60));
const easeOutQuad = (t) => t * (2 - t);
const animateCountUp = (el) => {
  let frame = 0;
  const countTo = parseInt(el.innerHTML.replace(/,/g, ""), 10);
  const counter = setInterval(() => {
    frame++;
    const progress = easeOutQuad(frame / totalFrames);
    const currentCount = Math.round(countTo * progress);
    if (parseInt(el.innerHTML.replace(/,/g, ""), 10) !== currentCount) {
      el.innerHTML = (currentCount + "").replace(
        /(\d)(?=(\d{3})+(?!\d))/g,
        "$1,"
      );
    }
    if (frame === totalFrames) {
      clearInterval(counter);
    }
  }, 1000 / 60);
};

export default {
  components: { FeatherIcon, VBTooltip },
  directives: {
    "b-tooltip": VBTooltip,
    ClickOutside
  },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },

  watch: {
    $route(to, from) {
      this.isMenu = false;
      document.body.classList.remove("open-menu");
      this.setCartValue();
    },
  },
  computed: {
    itemsInCart() {
      let cart = JSON.parse(localStorage.getItem("cart"));
      this.totalCount = cart.reduce((accum, item) => accum + item.amount, 0);
    },
    isReload() {
      return this.$store.state.app.isReload;
    }
  },
  mounted() {
    var t_ = this;
    EventBus.$on("CHANGE_LANG", function (payLoad) {
      t_.fetchFooter();
      t_.fetchHeader();
    });
    EventBus.$on('setCartValue', payLoad => {
      this.setCartValue();
    });

    if (new Date().getTime() - localStorage.tokenTime > 1000 * 60 * 100) {
      localStorage.clear();
    }
    this.isMenu = false;
    document.body.classList.remove("open-menu");

    this.fetchFooter();
    this.fetchHeader();

    window.hiddenHeader = (value) => {
      this.toggleHeader(value)
    }
    window.hiddenFooter = (value) => {
      this.toggleFooter(value)
    }
    window.hideLayout = (value) => {
      this.toggleHeader(value)
      this.toggleFooter(value)
      const chat = document.querySelector('#cs-live-chat')
      if (chat) {
        chat.style.display = 'none'
      }
    }
  },
  data() {
    return {
      lang: this.$locale,
      ConfigData: [],
      StaticPages: [],
      Campaigns: [],
      ForInvestors: {},
      AboutItel: {},
      toggleService: false,
      isMenu: false,
      toggleSupport: false,
      toggleLogoutMenu: false,
      headerVisible: true,
      footerVisible: true,
      isShowSearch: false,
      sSearch: '',
      isSearchResult: false,
      resultSearch: {},
      resultKeywordSearch: {},
      isShowNoti: false,
      keywordSearchResult: [],
      keywordSearchResultShop: [],
      MobileNumber: "",
      isValidMobileNumber: true,
      isLogin: false,
      totalCount: 0,
      categories: []
    };
  },

  methods: {
    reloadPage() {
      this.$store.dispatch('app/setIsReload', !this.isReload);
    },
    toggleHeader(value) {
      this.headerVisible = value
    },
    toggleFooter(value) {
      this.footerVisible = value
    },
    fetchFooter() {
      useJwt
        .post("getMasterData/footer", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.ConfigData = response.data.result.ConfigData;
          this.StaticPages = response.data.result.StaticPages;
        });
    },

    fetchHeader() {
      useJwt
        .post("getMasterData/header", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: this.$l,
        })
        .then((response) => {
          this.ForInvestors = response.data.result.ForInvestors[0];
          this.AboutItel = response.data.result.AboutItel[0];
          this.Campaigns = response.data.result.Campaigns;
        });
    },

    shipment() {
      this.$router.push({ path: "/shipment" });
    },
    runAnimations() {
      const countupEls = document.querySelectorAll(".count-ani");
      countupEls.forEach(animateCountUp);
    },
    lstore() {
      return localStorage;
    },
    logout() {
      localStorage.clear();
      this.$router.go();
    },
    changeLang(l) {
      this.lang = l;
      this.$locale = l;
      EventBus.$emit("CHANGE_LANG", l);
      // window.location.reload();
    },
    toggleMenu() {
      this.isMenu = !this.isMenu;
      if (this.isMenu) {
        document.body.classList.add("open-menu");
      } else {
        document.body.classList.remove("open-menu");
      }
    },
    toggleServiceMenu() {
      this.toggleService = !this.toggleService;
    },
    toggleSupportMenu() {
      this.toggleSupport = !this.toggleSupport;
    },
    toggleLogout() {
      this.toggleLogoutMenu = !this.toggleLogoutMenu;
    },
    quickSearchProduct() {
      if (!this.sSearch || this.sSearch.trim().length < 2) {
        this.$toast.warning(
            'Bạn phải nhập ít nhất 2 ký tự để tìm kiếm. Xin vui lòng thử lại!',
            {icon: true, closeButton: 'button'}
        );
        return;
      }
      this.loading(true);
      this.resultSearch = {};
      if (!isNaN(this.sSearch)) {
        console.log("searchPhone", this.sSearch.slice(3))
        if (this.sSearch.startsWith("087")) {
          localStorage.setItem("searchPhone", this.sSearch.slice(3))
        } else if (this.sSearch.startsWith("8487")) {
          localStorage.setItem("searchPhone", this.sSearch.slice(4))
        } else {
          localStorage.setItem("searchPhone", this.sSearch)
        }
        this.sSearch = ""
        if (this.$route.path === "/mua-hang") {
          this.loading(false)
          this.$router.go(this.$router.currentRoute)
        } else {
          router.push("/mua-hang")
        }
      } else {
        this.searchProduct();
        // this.searchByKeyword();
      }
    },
    searchProduct() {
      const langCode = this.lang === "vi" ? 1 : 2;
      this.keywordSearchResult = [];
      this.keywordSearchResultShop = [];
      this.categories = [];
      if (this.sSearch.toLowerCase().includes("tttb") || this.sSearch.toLowerCase().includes("đăng kí")) {
        this.keywordSearchResult.push({ Name: "Đăng kí TTTB", slug: "dang-ky-thong-tin-thue-bao" })
      }
      if (this.sSearch.toLowerCase().includes("đổi") || this.sSearch.toLowerCase().includes("cấp") || this.sSearch.toLowerCase().includes("sim") || this.sSearch.toLowerCase().includes("esim")) {
        this.keywordSearchResult.push({ Name: "Đổi/cấp ESIM", slug: "doi-cap-esim" })
        this.keywordSearchResult.push({ Name: "Đổi/cấp SIM", slug: "doi-cap-sim" })
      }
      if (this.sSearch.toLowerCase().includes("liên hệ")) {
        this.keywordSearchResult.push({ Name: "Liên Hệ", slug: "lien-he" })
      }
      if ("Sim phong thủy".toLowerCase().includes(this.sSearch.toLowerCase())) {
        this.categories.push({ Name: "Sim phong thủy", slug: "sim-phong-thuy", category: 'SimCategory' })
      }
      if ("Sim data".toLowerCase().includes(this.sSearch.toLowerCase())) {
        this.categories.push({ Name: "Sim data", slug: "mua-sim-data", category: 'SimCategory' })
      }
      if ("Tuyển dụng".toLowerCase().includes(this.sSearch.toLowerCase())) {
        this.categories.push({ Name: "Tuyển dụng", slug: "mua-sim-data", category: 'recruit' })
      }
      if ("Mua gói cước".toLowerCase().includes(this.sSearch.toLowerCase())) {
        this.categories.push({ Name: "Mua gói cước", slug: "thu-vien-goi-cuoc", category: 'thu-vien-goi-cuoc' })
      }
      if ("itel shop".toLowerCase().includes(this.sSearch.toLowerCase())) {
        this.keywordSearchResultShop.push({ Name: "Tài chính bảo hiểm", slug: "tai-chinh-bao-hiem" })
        this.keywordSearchResultShop.push({ Name: "Du lịch đi lại", slug: "du-lich" })
        this.keywordSearchResultShop.push({ Name: "Mua sắm", slug: "mua-sam" })
        this.keywordSearchResultShop.push({ Name: "Ăn uống", slug: "an-uong" })
      } else {
        if ("Tài chính bảo hiểm Mở tài khoản OCB Thẻ tín dụng VPBank VIB MB BSH".toLowerCase().includes(this.sSearch.toLowerCase())) {
          this.keywordSearchResultShop.push({ Name: "Tài chính bảo hiểm", slug: "tai-chinh-bao-hiem" })
        }
        if ("Du lịch đi lại mua vé Đặt vé vé xe vé máy bay online vntrip abtrip vexere".toLowerCase().includes(this.sSearch.toLowerCase())) {
          this.keywordSearchResultShop.push({ Name: "Du lịch đi lại", slug: "du-lich" })
        }
        if ("Mua sắm vé số vietlott shopee thương mại tiki cuncung mẹ và bé thời trang routine".toLowerCase().includes(this.sSearch.toLowerCase())) {
          this.keywordSearchResultShop.push({ Name: "Mua sắm", slug: "mua-sam" })
        }
        if ("Ăn uống highlands coffee".toLowerCase().includes(this.sSearch.toLowerCase())) {
          this.keywordSearchResultShop.push({ Name: "Ăn uống", slug: "an-uong" })
        }
      }
      useJwt
        .get('search-by-keyword/' + langCode + '/' + this.sSearch.toLowerCase())
        .then((response) => {
          if (response && response.status == 200) {
            this.resultSearch = response.data.result;
            if (response.data.result.Products.length > 0) {
              if (response.data.result.Products.length > 0) {
                response.data.result.Products
                  .forEach((m) => {
                    m.images = JSON.parse(m.images);
                    m.variants = JSON.parse(m.variants);
                    m.categories = JSON.parse(m.categories);
                    m.meta = JSON.parse(m.meta);
                    if (m.origin_type == OriginType.BASUES) {
                      m.image_url = m.images[0].src;
                      m.price = m.variants[0].price;
                      m.base_price = m.variants[0].base_price;
                    } else {
                      m.image_url = m.images[0].image_url;
                    }
                  });
                this.resultSearch.Products = response.data.result.Products;
              }
            }
            console.log("resultSearch", this.resultSearch)
            this.isSearchResult = true;
          }
          this.loading(false);
          if (this.keywordSearchResult.length === 0 && this.keywordSearchResultShop.length === 0
            && this.resultSearch.AboutItel.length === 0 && this.resultSearch.BlogLoyaltys.length === 0
            && this.resultSearch.Campaigns.length === 0 && this.resultSearch.Categories.length === 0
            && this.resultSearch.DataPackages.length === 0 && this.resultSearch.ForInvestors.length === 0
            && this.resultSearch.News.length === 0 && this.resultSearch.Products.length === 0
            && this.resultSearch.Questions.length === 0 && this.resultSearch.StaticPages.length === 0
            && this.resultSearch.Recruitment.length === 0 && this.categories.length === 0) {
            if (this.resultSearch.Vouchers && this.resultSearch.Vouchers.length === 0) {
              this.isSearchResult = false;
              this.$toast.error(
                'Không tìm thấy thông tin.',
                { icon: true, closeButton: 'button' })
            } else {
              this.isSearchResult = false;
              this.$toast.error(
                'Không tìm thấy thông tin.',
                { icon: true, closeButton: 'button' })
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading(false);
          if (this.keywordSearchResult.length === 0 && this.keywordSearchResultShop.length === 0 && this.categories.length === 0) {
            this.$toast.error(
              'Không tìm thấy thông tin.',
              { icon: true, closeButton: 'button' })
          }
        }
        );
    },
    setCartValue() {
      if (localStorage.getItem("cart")) {
        var total = 0;
        var carts = JSON.parse(localStorage.getItem("cart"));
        carts.forEach(function (item) {
          total += (item.amount ? item.amount : 0);
        });
        this.totalCount = total;
      }
    },
    // searchByKeyword() {
    //   const langCode = this.lang === "vi" ? 1 : 2
    //   useJwt
    //       .get('search-by-keyword/' + langCode + '/' + this.sSearch.toLowerCase())
    //       .then((response) => {
    //         if (response && response.status == 200) {
    //           this.resultKeywordSearch = response.data.result
    //           this.searchProduct();
    //           console.log("keyword", response.data.result)
    //           this.isSearchResult = true;
    //         }
    //         this.loading(false);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.loading(false);
    //         this.$toast.error(
    //             'Không tìm thấy thông tin.',
    //             {icon: true, closeButton: 'button'})
    //       });
    // },
    showQuikcSearch(isShow) {
      this.isShowSearch = isShow;
      let self = this;
      setTimeout(() => {
        self.$refs.txtSearch.focus();
      }, 100);

    },
    hideResultSearch() {
      this.isSearchResult = false;
    },
    BuyNow(p) {
      this.sSearch = '';
      this.isSearchResult = false;
      this.$router.push({
        name: 'product-detail',
        params: {
          originType: p.origin_type,
          slugPath: p.categories[0].slug,
          Slug: p.meta.slug,
        },
      });
    },
    watchNow(p, type) {
      this.sSearch = '';
      this.isSearchResult = false;
      console.log("type", type)
      console.log("p", p.BlogCategorySlug + '/' + p.Slug)
      switch (type) {
        case 'News':
          console.log("run new")
          this.$router.push({
            name: 'tin-tuc-detail',
            params: {
              categoryId: p.BlogCategorySlug,
              Id: p.Slug,
            },
          })
          break;
        case 'Questions':
          console.log("run quest")
          this.$router.push({
            name: 'ho-tro-slug',
            params: {
              Slug: 'hoi-dap',
            },
          });
          break;
        case 'AboutItel':
          console.log("run AboutItel")
          this.$router.push({
            name: 've-itel',
            params: {
              Slug: p.Slug,
            },
          });
          break;
        case 'CampaignMenus':
          console.log("run CampaignMenus")
          if (p.Slug.includes('https')) {
            window.location.href = p.Slug
          } else {
            this.$router.push({
              name: 'chien-dich',
              params: {
                CategorySlug: p.Slug,
              },
            });
          }
          break;
        case 'DataPackages':
          console.log("run DataPackages")
          this.$router.push({
            name: 'thu-vien-goi-cuoc',
          });
          break;
        case 'Category':
          console.log("run Category")
          if (p.category_name === "Điện thoại" || p.category_name === "Máy tính bảng") {
            this.$router.push({
              name: 'shop',
              params: {
                originType: p.origin_type,
                parentSlug: p.slug,
                Slug: p.slug,
              }
            });
          } else {
            this.$router.push({
              name: 'shop',
              params: {
                originType: p.origin_type,
                parentSlug: 'phu-kien-hot',
                Slug: p.slug,
              }
            });
          }
          break;
        case 'KeyWord':
          this.$router.push({
            name: 'ho-tro-slug',
            params: {
              Slug: p.slug,
            },
          });
          break;
        case 'Campaigns':
          this.$router.push({
            path: "/chien-dich/-/" + p.Slug,
          });
          break;
        case 'ForInvestors':
          this.$router.push({
            name: 've-itel',
            params: {
              Slug: p.Slug,
            },
          });
          break;
        case 'BlogLoyaltys':
          this.$router.push({
            name: 'club-blog/gioi-thieu',
            params: {
              Slug: p.Slug,
            },
          });
          break;
        case 'StaticPages':
          console.log("StaticPages")
          this.$router.push({
            name: 've-itel',
            params: {
              Slug: p.Slug,
            },
          });
          break;
        case 'ItelShop':
          console.log("slug", p.slug)
          this.$router.push({
            name: 'shop-home',
            params: {
              slug: p.slug,
            },
          });
          break;
        case 'Vouchers':
          this.$router.push({
            name: 'club/voucher-detail',
            params: {
              Slug: p.voucher_id,
            },
          });
          break;
        case 'SimCategory':
          this.$router.push({
            name: p.slug,
          });
          break;
        case 'Recruitment':
          this.$router.push({
            name: 'recruit-detail',
            params: {
              Id: p.Slug,
            },
          });
          break;
        case 'recruit':
          this.$router.push({
            name: 'tuyen-dung'
          });
          break;
        case 'thu-vien-goi-cuoc':
          this.$router.push({
            name: 'thu-vien-goi-cuoc'
          });
          break;
      }
    },
    goToShow(c) {
      if (localStorage.getItem('phoneWholeSale')) {
        this.MobileNumber = localStorage.getItem('phoneWholeSale');
        this.isLogin = true;
      }
      this.$bvModal.show("loginPhone");
    },
    gotoCart() {
      this.$router.push({ name: 'thanh-toan' });
    },
    validateMobileNumber() {
      const validationRegex = /^\d{10}$/;
      if (this.MobileNumber.match(validationRegex)) {
        this.isValidMobileNumber = true;
      } else {
        this.isValidMobileNumber = false;
      }
    },
    loginPhone() {
      if (!this.MobileNumber) {
        this.isValidMobileNumber = false;
        return;
      }
      if (!this.isValidMobileNumber) {
        return;
      }
      localStorage.setItem('phoneWholeSale', this.MobileNumber);
      this.$bvModal.hide("loginPhone");
      this.$bvModal.show("confirmWholeSale");
      if (sessionStorage.getItem("cart_info")) {
        sessionStorage.removeItem("cart_info");
      }
      if (localStorage.getItem("cart")) {
        localStorage.removeItem("cart");
      }
    },
    confirmClose() {
      this.removeCacheLogin();
      this.$bvModal.hide("confirmWholeSale");
      // if (this.$route.name == 'products' || this.$route.name == 'products-home' || this.$route.name == 'home' || this.$route.name == 'thanh-toan') {
      //   location.reload();
      // }
    },
    confirmNext() {
      this.$bvModal.hide("confirmWholeSale");
      if (this.$route.name == 'products' || this.$route.name == 'products-home') {
        location.reload();
      } else {
        this.$router.push({ name: 'products', params: { Slug: 'tat-ca' } });
      }
    },
    logoutPhone() {
      this.removeCacheLogin();
      this.$bvModal.hide("loginPhone");
      location.reload();
    },
    removeCacheLogin() {
      this.MobileNumber = '';
      this.isLogin = false;
      localStorage.removeItem('phoneWholeSale');
      if (sessionStorage.getItem("cart_info")) {
        sessionStorage.removeItem("cart_info");
      }
      if (localStorage.getItem("cart")) {
        localStorage.removeItem("cart");
      }
    }
  },
};
</script>
<style>
.ul-quick-search {
  width: 250px;
  display: inline-block;
  position: absolute;
  top: 45px;
  left: 2px;
  max-height: 300px;
  background-color: #fff;
  padding: 20px 8px;
  overflow-y: auto;
}

.ul-quick-search li img {
  width: 100%;
  height: auto;
  max-width: 60px;
  border-radius: 3px;

}

.ul-quick-search li {
  align-items: center;
  width: 100%;
  float: left;
}

.ul-quick-search li,
.ul-quick-search li .search-img,
.ul-quick-search li .search-info {
  display: inline-block;

}

.ul-quick-search li .search-img {
  width: 60px;
  padding-right: 10px;
  vertical-align: middle;
  align-items: center;
  text-align: left;
  float: left;
}

.ul-quick-search li .search-info {
  width: calc(100% - 70px);
  padding-top: 5px;
  text-align: left;
}

.ul-quick-search li .price {
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 18px;
  color: #E9182C;
  padding: 0 5px;
}

.ul-quick-search li .base_price {
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  color: #525252;
  text-decoration-line: line-through;
  padding: 0 5px;
}

.ul-quick-search .title-search {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title-search-header {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-size: 12px;
  color: #000000;
  background: #D9D9D9;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 450px) {
  .desktop-img {
    display: none;
  }

  .box-select-image .fs-box {
    max-width: 100%;
    height: 75vw;
  }

  .avatar-txt {
    top: 60vw;
  }
}

@media screen and (min-width: 451px) {
  .mobile-img {
    display: none;
  }
}

.select2-container .select2-selection--single {
  height: 5rem !important;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: none;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1.8rem;
  padding: 1rem;
  font-weight: 350;
}

.select2-container .select2-selection--single {
  border-radius: 1rem !important;
  height: 5rem !important;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: none;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1.8rem;
  padding: 1rem;
  font-weight: 350;
}

.select2-sm1 .select2-container .select2-selection--single {
  border-radius: 0.6rem !important;
  height: 4rem !important;
}

.select2-sm1 .select2-container .select2-selection--single {
  height: 4rem;
  font-size: 1.6rem;
  padding: 0.5rem;
  font-weight: 350;
}

.select2-results__option,
.select2-search__field {
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 1.7rem;
  padding: 1rem !important;
  font-weight: 350 !important;
}

.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjRUQxRjI0IiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") center / contain no-repeat;
}

.select2-selection__arrow {
  background: none;
  display: none !important;
}

h5 {
  font-size: 1.8rem !important;
}

fieldset {
  padding: 0 12% 0 0;
  margin: 0;
  border: 0;
}

.custom-control-label {
  padding: 0 0 0 0.7rem !important;
  display: inline-block !important;
  vertical-align: middle !important;
  color: #000 !important;
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.fs-error-txt {
  position: inherit !important;
  line-height: 1.5;
  font-size: 1.4rem;
}

.red-border-class {
  border: 2px solid #ed1f24 !important;
}

.is-invalid {
  border: 1px solid #ed1f24 !important;
  border-radius: 6px;
}

.is-invalid .vs__dropdown-toggle {
  border: none;
}

.is-invalid .select2-selection--single::before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
}

.select2-container--default .select2-selection--single {
  border: 1px solid #d8d6de;
}

.btn {
  font-size: 2.4rem;
  font-weight: 400;
  height: 5rem;
  padding: 1.06rem 2.5rem;
  -webkit-border-radius: 1rem !important;
  border-radius: 1rem !important;
}

.btn-sm1 {
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0.36rem 1.3rem;
}

.fz-1rem {
  font-size: 1.3rem !important;
  line-height: 1.6rem !important;
}

.auth-wrapper.auth-v1 {
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.px-2 {
  padding-left: 1.5rem !important;
}

.auth-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 60vh;
  width: 100%;
  font-size: 2.4rem;
  font-weight: 400;
}

/*.pack-txt {
    flex: 0;
    max-width: 100%;
    padding: 0.5rem;
}*/
.auth-wrapper h1 {
  margin-bottom: 3.6rem;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
}

.form-control {
  border-radius: 0.5rem;
  height: 5rem;
  box-shadow: none;
  font-size: 1.8rem;
  padding: 1rem;
  font-weight: 350;
  -webkit-border-radius: 1rem !important;
  border: radius 1rem !important;
}

.form-control-sm1 {
  border-radius: 0.6rem !important;
  height: 4rem !important;
  box-shadow: none;
  font-size: 1.6rem !important;
  padding: 1rem;
  font-weight: 350;
  -webkit-border-radius: 1rem !important;
  border: radius 1rem !important;
}

.custom-checkbox.b-custom-control-sm1 .custom-control-label::before {
  top: -0.225rem;
  width: 1.3rem;
  height: 1.3rem;
}

.custom-checkbox.b-custom-control-sm1 .custom-control-label {
  font-size: 1rem !important;
  margin-top: -0.225rem;
  padding: 0 !important;
}

.custom-checkbox.b-custom-control-sm1 .custom-control-label::after {
  top: -0.225rem;
  width: 14px;
  height: 14px;
}
.body-content-whole  {
  padding: 5px 0px;
}
@media screen and (max-width: 450px) {
  .auth-inner {
    min-width: 100%;
  }

  .card {
    padding: 1rem;
    -webkit-border-radius: 1rem !important;
    border-radius: 1rem !important;
  }
 
  .cart-view .total-product,
  .cart-view .total-product:hover,
  .cart-view .total-product:active {
    right: 0px;
    top: 1px;
  }
}

@media screen and (min-width: 451px) {
  .auth-inner {
    min-width: 450px;
  }

  .card {
    padding: 4rem;
    -webkit-border-radius: 1rem !important;
    border-radius: 1rem !important;
  }
}

.d-block {
  margin-bottom: 1rem;
  padding-top: 1rem;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #151522;
}

.rounded {
  -webkit-border-radius: 1rem !important;
  border-radius: 1rem !important;
}

.support-top h2 {
  font-size: 3.2rem;
}

.support-top h3 {
  font-size: 2rem;
  font-weight: 500;
}

.support-top h4 {
  font-size: 1.4rem;
  font-weight: 400;
}

.text {
  font-size: 1.4rem;
  font-weight: normal;
}

.text_lh {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 2rem;
}

.text_lg {
  font-size: 18px;
}

.page-item .page-link {
  font-size: 2.4rem;
  min-width: 4rem;
}

.huge-text {
  font-size: 3.2rem;
  font-weight: 500;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  float: right;
  padding-right: 8px;
  font-size: 2rem;
}

.tooltip {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.2;
  width: auto;
  font-family: "Roboto";
  font-style: normal;
}

.tooltip-inner {
  max-width: 300px;
}

#loginPhone .modal-content,#confirmWholeSale .modal-content {
  max-width: 360px !important;
  border-radius: 10px;
  margin: 0 auto;
  text-align: center;
}

#loginPhone .close {
  border-radius: 10px;
}
</style>
<style scoped>
.body-content {
  padding: 5px 40px;
}
 
.btn-login-continues,
.btn-confirm-next,
.btn-confirm-close {
  display: block;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 12px;
  padding: 0 !important;
}

.btn-confirm-next,
.btn-confirm-close {
  display: inline-block;
  margin: 0 10px;
  width: 120px;
}

.btn-confirm-close {
  border: 2px solid red;
  width: 120px;
}

.text-order {
  font-size: 16px !important;
}

.text-danger-error {
  color: red;
  font-size: 13px;
  padding: 4px 0;
}

.form-group {
  margin-bottom: 1rem;
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
}

.form-group label {
  color: #333;
  font-size: 14px;
  padding: 4px 0;
}

.form-group .form-control {
  padding: 0.438rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
  color: #000;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: 36px;
  background-color: #efefef;
}

.form-group .form-control::placeholder {
  transition: all .2s ease;
}

.form-group .form-control::placeholder {
  color: #b9b9c3;
  opacity: 1;
}

.text-header {
  font-size: 24px;
  font-weight: bold;
  color: #5c5671;
}

.show {
  color: #fff !important;
}

.hdr-login a::after {
  padding: 0;
  content: "";
}

@media screen and (min-width: 1101px) {
  .hdr-login a:hover {
    color: hsla(0, 0%, 100%, 0.5) !important;
  }

}</style>
